<template>
  <div class="w-full h-full px-3 py-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <div class="flex flex-col gap-8">
        <div class="flex justify-start items-center gap-0">
          <back-button @onClick="$router.back()" variant="secondary" />
          <div class="flex justify-start items-center gap-6">
            <h1 class="text-xl text-left font-extrabold">Leave</h1>
            <breadcrumb :items="breadcrumbs" />
          </div>
        </div>
        <div class="flex flex-col gap-0 my-5">
          <div class="shadow rounded">
            <tab
              :border="true"
              :tabs="tabs"
              :active-tab="currentTab"
              @currentTab="handleCurrentTab($event)"
            />
          </div>
          <calendar-view-annual-plan v-if="currentTab === 'Calendar Summary View'" />
          <annual-leave-plan-details v-if="currentTab === 'Detailed View'" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import Loader from "@scelloo/cloudenly-ui/src/components/loader"
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
import Tab from "@scelloo/cloudenly-ui/src/components/tab";

export default {
  components: {
    BackButton,
    Loader,
    Breadcrumb,
    Tab,

    AnnualLeavePlanDetails: () => import("./AnnualLeavePlanDetails"),
    CalendarViewAnnualPlan: () => import("./CalendarViewAnnualPlan"),
  },
  computed: {
    breadcrumbs(){
      return [
        { disabled: false, text: "Leave", href: "Leave", id: "Leave" },
        { disabled: false, text: "Leave Plan", href: "Leave Plan", id: "Leave Plan" },
        { disabled: false, text: `${this.leaveYear} Leave Plan`, href: `${this.leaveYear} Leave Plan`, id: `${this.leaveYear} Leave Plan` },
      ]
    }
  },
  data() {
    return {
      isLoading: true,
      planId: '',
      leaveYear: '',
      currentTab: this.$route.query.currentTab || "Calendar Summary View",
      tabs: [
        'Calendar Summary View',
        'Detailed View',
      ],
    };
  },
  methods: {
    handleCurrentTab(currentTab) {
      if (this.currentTab !== currentTab) {
        this.currentTab = currentTab
      }
    }
  },
  created(){
    this.isLoading = true
    this.leaveYear = this.$route.params.leaveYear
    this.planId = this.$route.params.id
    this.isLoading = false
  }
};
</script>
